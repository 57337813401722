import { FetchFoldersResponse, initialFolder, Folder, FolderKey } from '@api/models'
import { toast, useModal } from '@components/common'
import {
  useCreateFolderMutation,
  useDeleteFolderMutation,
  useEditFolderMutation,
  useFetchFoldersQuery,
} from '@api'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTableParams } from '@hooks'
import { TableParamsHook } from 'hooks/useTableParams'

export interface FoldersHook extends TableParamsHook {
  filterState: Object
  setFilterState: Dispatch<SetStateAction<{}>>
  searchKey: string
  selectedFolder: Folder

  folders: FetchFoldersResponse
  isLoadingFolders: boolean
  isErrorFetchingFolders: boolean
  isRefetchingFolders: boolean

  handleEditFolder: (payload: Folder) => Promise<void>
  handleCreateFolder: (payload: Folder) => Promise<void>
  isLoadingEditFolder: boolean
  isLoadingCreateFolder: boolean

  handleDeleteFolder: (payload: Folder) => Promise<void>
  isLoadingDeleteFolder: boolean

  deleteFolderModalIsOpen: boolean
  triggerDeleteFolderModal
  closeDeleteFolderModal
  openDeleteFolderModal: (folder: Folder) => void
  openEditFolderModal: (folder: Folder) => void
  triggerEditFolderModal
  closeEditFolderModal
  editFolderModalIsOpen: boolean

  openCreateFolderModal: () => void
  createFolderModalIsOpen: boolean
  closeCreateFolderModal
}

export default function useFolders(): FoldersHook {
  const [filterState, setFilterState] = useState({})
  const [selectedFolder, setSelectedFolder] = useState<Folder>(undefined)

  const searchKey: FolderKey = 'FL_FOLD_ID'
  const initialItemsPerPage = 20

  // Use useTableParams hook for table filtering, pagination, etc.
  const {
    pageNumber,
    itemsPerPage,
    fetchRequestPayload,
    handleSearch,
    searchValue,
    changePage,
    sortByInitialState,
    updateSortSearchParam,
    isTotalCountEmpty,
  } = useTableParams({
    searchKey,
    filterState,
    setFilterState,
    initialItemsPerPage,
  })

  // Fetch Folders
  const {
    data: folders,
    isLoading: isLoadingFolders,
    isError: isErrorFetchingFolders,
    isFetching: isRefetchingFolders,
  } = useFetchFoldersQuery(fetchRequestPayload)

  // MODAL STATES
  // Delete modal
  const {
    modalIsOpen: deleteFolderModalIsOpen,
    triggerModal: triggerDeleteFolderModal,
    closeModal: closeDeleteFolderModal,
  } = useModal()
  function openDeleteFolderModal(folder: Folder): void {
    setSelectedFolder(folder)
    triggerDeleteFolderModal()
  }

  // Edit modal
  const {
    modalIsOpen: editFolderModalIsOpen,
    triggerModal: triggerEditFolderModal,
    closeModal: closeEditFolderModal,
  } = useModal()

  function openEditFolderModal(folder: Folder): void {
    setSelectedFolder(folder)
    triggerEditFolderModal()
  }

  // Create modal
  const {
    modalIsOpen: createFolderModalIsOpen,
    triggerModal: triggerCreateFolderModal,
    closeModal: closeCreateFolderModal,
  } = useModal()
  function openCreateFolderModal(): void {
    setSelectedFolder(initialFolder)
    triggerCreateFolderModal()
  }

  // MUTATIONS
  // Delete
  const [deleteFolder, { isLoading: isLoadingDeleteFolder }] = useDeleteFolderMutation()
  async function handleDeleteFolder(payload: Folder): Promise<void> {
    try {
      await deleteFolder(payload).unwrap()
      closeDeleteFolderModal()
      toast.success(`Successfully deleted "${payload.FL_TAB_VL}" from your folders.`)
    } catch (error: any) {
      toast.error(error.data || 'There was an unexpected error deleting this folder.')
    }
  }

  // Edit
  const [editFolder, { isLoading: isLoadingEditFolder }] = useEditFolderMutation()
  async function handleEditFolder(payload: Folder): Promise<void> {
    try {
      await editFolder(payload).unwrap()
      closeEditFolderModal()
      toast.success(`Folder "${payload.FL_TAB_VL}" successfully updated.`)
    } catch (error: any) {
      toast.error(error.data || 'There was an unexpected error updating this folder.')
    }
  }

  // Create
  const [createFolder, { isLoading: isLoadingCreateFolder }] = useCreateFolderMutation()
  async function handleCreateFolder(payload: Folder): Promise<void> {
    try {
      await createFolder(payload).unwrap()

      closeCreateFolderModal()
      toast.success(`Successfully added ${payload.FL_TAB_VL} to folders.`)
    } catch (error: any) {
      toast.error(error.data || 'There was an unexpected error creating the folder.')
    }
  }

  return {
    // tableParams return
    pageNumber,
    itemsPerPage,
    fetchRequestPayload,
    handleSearch,
    searchValue,
    changePage,
    sortByInitialState,
    updateSortSearchParam,
    isTotalCountEmpty,
    //
    filterState,
    setFilterState,
    searchKey,
    selectedFolder,

    folders,
    isLoadingFolders,
    isErrorFetchingFolders,
    isRefetchingFolders,

    handleDeleteFolder,
    isLoadingDeleteFolder,

    handleEditFolder,
    handleCreateFolder,
    isLoadingCreateFolder,
    isLoadingEditFolder,

    deleteFolderModalIsOpen,
    triggerDeleteFolderModal,
    closeDeleteFolderModal,
    openDeleteFolderModal,
    openEditFolderModal,
    triggerEditFolderModal,
    closeEditFolderModal,
    editFolderModalIsOpen,

    createFolderModalIsOpen,
    openCreateFolderModal,
    closeCreateFolderModal,
  }
}
