import { FetchVendorsResponse, initialVendor, Vendor, VendorKey } from '@api/models'
import { toast, useModal } from '@components/common'
import {
  useCreateVendorMutation,
  useDeleteVendorMutation,
  useEditVendorMutation,
  useFetchVendorsQuery,
} from '@api'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTableParams } from '@hooks'
import { TableParamsHook } from 'hooks/useTableParams'

export interface VendorsHook extends TableParamsHook {
  filterState: Object
  setFilterState: Dispatch<SetStateAction<{}>>
  searchKey: string
  selectedVendor: Vendor

  vendors: FetchVendorsResponse
  isLoadingVendors: boolean
  isErrorFetchingVendors: boolean
  isRefetchingVendors: boolean

  handleEditVendor: (payload: Vendor) => Promise<void>
  handleCreateVendor: (payload: Vendor) => Promise<void>
  isLoadingEditVendor: boolean
  isLoadingCreateVendor: boolean

  handleDeleteVendor: (payload: Vendor) => Promise<void>
  isLoadingDeleteVendor: boolean

  deleteVendorModalIsOpen: boolean
  triggerDeleteVendorModal
  closeDeleteVendorModal
  openDeleteVendorModal: (vendor: Vendor) => void
  openEditVendorModal: (vendor: Vendor) => void
  triggerEditVendorModal
  closeEditVendorModal
  editVendorModalIsOpen: boolean

  openCreateVendorModal: () => void
  createVendorModalIsOpen: boolean
  closeCreateVendorModal
}

export default function useVendors(): VendorsHook {
  const [filterState, setFilterState] = useState({})
  const [selectedVendor, setSelectedVendor] = useState<Vendor>(undefined)

  const searchKey: VendorKey = 'VN_NAME'
  const initialItemsPerPage = 20

  // Use useTableParams hook for table filtering, pagination, etc.
  const {
    pageNumber,
    itemsPerPage,
    fetchRequestPayload,
    handleSearch,
    searchValue,
    changePage,
    sortByInitialState,
    updateSortSearchParam,
  } = useTableParams({
    searchKey,
    filterState,
    setFilterState,
    initialItemsPerPage,
  })

  // Fetch Vendors
  const {
    data: vendors,
    isLoading: isLoadingVendors,
    isError: isErrorFetchingVendors,
    isFetching: isRefetchingVendors,
  } = useFetchVendorsQuery(fetchRequestPayload)

  // MODAL STATES
  // Delete modal
  const {
    modalIsOpen: deleteVendorModalIsOpen,
    triggerModal: triggerDeleteVendorModal,
    closeModal: closeDeleteVendorModal,
  } = useModal()
  function openDeleteVendorModal(vendor: Vendor): void {
    setSelectedVendor(vendor)
    triggerDeleteVendorModal()
  }

  // Edit modal
  const {
    modalIsOpen: editVendorModalIsOpen,
    triggerModal: triggerEditVendorModal,
    closeModal: closeEditVendorModal,
  } = useModal()

  function openEditVendorModal(vendor: Vendor): void {
    setSelectedVendor(vendor)
    triggerEditVendorModal()
  }

  // Create modal
  const {
    modalIsOpen: createVendorModalIsOpen,
    triggerModal: triggerCreateVendorModal,
    closeModal: closeCreateVendorModal,
  } = useModal()
  function openCreateVendorModal(): void {
    setSelectedVendor(initialVendor)
    triggerCreateVendorModal()
  }

  // MUTATIONS
  // Delete
  const [deleteVendor, { isLoading: isLoadingDeleteVendor }] = useDeleteVendorMutation()
  async function handleDeleteVendor(payload: Vendor): Promise<void> {
    try {
      await deleteVendor(payload).unwrap()
      closeDeleteVendorModal()
      toast.success(`Successfully deleted "${payload.VN_NAME}" from your vendors.`)
    } catch (error: any) {
      toast.error(error.data || 'There was an unexpected error deleting this vendor.')
    }
  }

  // Edit
  const [editVendor, { isLoading: isLoadingEditVendor }] = useEditVendorMutation()
  async function handleEditVendor(payload: Vendor): Promise<void> {
    try {
      await editVendor(payload).unwrap()
      closeEditVendorModal()
      toast.success(`Vendor "${payload.VN_NAME}" successfully updated.`)
    } catch (error: any) {
      toast.error(error.data || 'There was an unexpected error updating this vendor.')
    }
  }

  // Create
  const [createVendor, { isLoading: isLoadingCreateVendor }] = useCreateVendorMutation()
  async function handleCreateVendor(payload: Vendor): Promise<void> {
    try {
      await createVendor(payload).unwrap()
      closeCreateVendorModal()
      toast.success(`Successfully added ${payload.VN_NAME} to vendors.`)
    } catch (error: any) {
      toast.error(error.data || 'There was an unexpected error creating this vendor.')
    }
  }

  return {
    // tableParams return
    pageNumber,
    itemsPerPage,
    fetchRequestPayload,
    handleSearch,
    searchValue,
    changePage,
    sortByInitialState,
    updateSortSearchParam,
    //
    filterState,
    setFilterState,
    searchKey,
    selectedVendor,

    vendors,
    isLoadingVendors,
    isErrorFetchingVendors,
    isRefetchingVendors,

    handleDeleteVendor,
    isLoadingDeleteVendor,

    handleEditVendor,
    handleCreateVendor,
    isLoadingCreateVendor,
    isLoadingEditVendor,

    deleteVendorModalIsOpen,
    triggerDeleteVendorModal,
    closeDeleteVendorModal,
    openDeleteVendorModal,
    openEditVendorModal,
    triggerEditVendorModal,
    closeEditVendorModal,
    editVendorModalIsOpen,

    createVendorModalIsOpen,
    openCreateVendorModal,
    closeCreateVendorModal,
  }
}
