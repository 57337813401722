export type ReportFilter = {
  Name: string
  List?: string[]
  From?: string
  To?: string
  Total?: boolean
  SearchType?: string
}

export type FileFormat = 'pdf' | 'csv' | 'excel'

interface ListReportParams {
  fileFormat: FileFormat
  // TODO: styles, leave this until its done on the backend
}

interface AssetRegisterSectionOptions {
  identification: boolean
  acquisition: boolean
  disposal: boolean
  transfers: boolean
  corporateProfile: boolean
  taxProfile: boolean
  stateProfile: boolean
  otherProfile: boolean
  corporateHistory: boolean
  taxHistory: boolean
  stateHistory: boolean
  otherHistory: boolean
  service: boolean
  location: boolean
  taskActivity: boolean
  folders: boolean
}

interface AssetRegisterReportParams {
  assetCompanyID: number
  sections: AssetRegisterSectionOptions
  sectionComments: AssetRegisterSectionOptions
  assets: string[]
  filter: ReportFilter[]
  fileFormat: FileFormat
}

interface AdminInsuranceReportParams {
  assetCompanyID: number
  profile: string
  includeDisposals: boolean
  includeCaps: boolean
  filter: ReportFilter[]
  fileFormat: FileFormat
}

interface AdminMaintenanceReportParams {
  assetCompanyID: number
  profile: string
  filter: ReportFilter[]
  includeCaps: boolean
  includeDisposals: boolean
  includeSerialID: boolean
  includePrimaryID: boolean
  includeSecondaryID: boolean
  includeCategoryID: boolean
  fileFormat: FileFormat
}

interface AdminPropertiesReportParams {
  assetCompanyID: number
  filter: ReportFilter[]
  includeDisposals: boolean
  includeAssetDesc: boolean
  includeCategoryDesc: boolean
  includeTaxClassDesc: boolean
  fileFormat: FileFormat
}

interface FinancialAdditionsReportParams {
  assetCompanyID: number
  filter: ReportFilter[]
  profile: string
  fromDate: string
  toDate: string
  includeCaps: boolean
  includeAcqPostCode: boolean
  includeExtraDesc: boolean
  includeAddedBy: boolean
  selectByInvoiceDate: boolean
  includeDisposals: boolean
  fileFormat: FileFormat
}

interface FinancialTransfersReportParams {
  assetCompanyID: number
  filter: ReportFilter[]
  profile: string
  fromDate: string
  toDate: string
  includeCaps: boolean
  includeAssetDesc: boolean
  includeDateInService: boolean
  includeDisposals: boolean
  includeTransferSystemDate: boolean
  fileFormat: FileFormat
}

interface FinancialDisposalsReportParams {
  assetCompanyID: number
  filter: ReportFilter[]
  profile: string
  fromDate: string
  toDate: string
  includeSalvageAmt: boolean
  includeCaps: boolean
  fileFormat: FileFormat
}

interface FinancialCapitalizedCostsReportParams {
  assetCompanyID: number
  filter: ReportFilter[]
  fromDate: string
  toDate: string
  includeDisposals: boolean
  includeAssetDesc: boolean
  fileFormat: FileFormat
}

interface FinancialGLPostSummaryReportParams {
  assetCompanyID: number
  filter: ReportFilter[]
  fromDate: string
  toDate: string
  profile: string
  totalsOnly: boolean
  includeDisposals: boolean
  includeAssetDesc: boolean
  includeEventDesc: boolean
  includeSystemDate: boolean
  fileFormat: FileFormat
}

interface FinancialGLPostPreviewReportParams {
  assetCompanyID: number
  filter: ReportFilter[]
  profile: string
  totalsOnly: boolean
  includeDisposals: boolean
  includeAssetDesc: boolean
  includeEventDesc: boolean
  includeSystemDate: boolean
  fileFormat: FileFormat
}

interface DeprecSummaryReportParams {
  assetCompanyID: number
  filter: ReportFilter[]
  profile: string
  asOfDate: string
  includeCaps: boolean
  includeAccelCode: boolean
  includeDeprecSystemCode: boolean
  includeDeprecUserCode: boolean
  includeAcqCost: boolean
  includeYears: boolean
  includeSerialID: boolean
  totalsOnly: boolean
  includeDisposals: boolean
  includeAssetDesc: boolean
  fileFormat: FileFormat
}
interface DeprecContinuityReportParams {
  assetCompanyID: number
  profile: string
  filter: ReportFilter[]
  fromDate: string
  toDate: string
  includeCaps: boolean
  includeDisposals: boolean
  selectByInvoiceDate: boolean
  totalsOnly: boolean
  fileFormat: FileFormat
}
interface DeprecHistoryReportParams {
  assetCompanyID: number
  profile: string
  filter: ReportFilter[]
  fromDate: string
  toDate: string
  includeCaps: boolean
  includeAccCode: boolean
  includeDeprSys: boolean
  includeDeprUsr: boolean
  includeYr: boolean
  includeDisposals: boolean
  includeAssetDesc: boolean
  includeUndeprecAssets: boolean
  includeDeprecAsOfDate: boolean
  fileFormat: FileFormat
}
interface DeprecEditReportParams {
  assetCompanyID: number
  profile: string
  filter: ReportFilter[]
  includeDisposals: boolean
  includeCaps: boolean
  includeAccCode: boolean
  includeDeprSys: boolean
  includeDeprUsr: boolean
  includeYr: boolean
  totalsOnly: boolean
  fileFormat: FileFormat
}
interface DeprecComparisonReportParams {}

export const INTERVALS = [
  'Monthly',
  'Periodic',
  'Quarterly',
  'Semi-Annually',
  'Yearly',
  'Entire Range',
] as const

export type Interval = typeof INTERVALS[number]

interface DeprecProjectionReportParams {
  assetCompanyID: number
  profile: string
  fromDate: string
  toDate: string
  interval: Interval
  filter: ReportFilter[]
  includeCaps: boolean
  fileFormat: FileFormat
}
interface DeprecProductionReportParams {
  assetCompanyID: number
  filter: ReportFilter[]
  profile: string
  fromYr: number
  toYr: number
  // includeDisposals: boolean
  includeAssetDesc: boolean
  fileFormat: FileFormat
}

interface PurchaseAnalysisReportParams {
  assetCompanyID: number
  fromDate: string
  toDate: string
  filter: Array<ReportFilter>
  includeCaps: boolean
  includeDisposals: boolean
  includeAssetDesc: boolean
  includeRequisitioner: boolean
  includeVendorName: boolean
  includePrimaryID: boolean
  includeSecondaryID: boolean
  includeDateAdded: boolean
  includeLeaseID: boolean
  format: number
  fileFormat: FileFormat
}

interface PurchaseAnalysisSpreadsheetReportParams {
  assetCompanyID: number
  fromDate: string
  toDate: string
  interval: Interval
  filter: ReportFilter[]
  includeCaps: boolean
  includeDisposals: boolean
  fileFormat: FileFormat
}

interface LocationCurrentReportParams {
  assetCompanyID: number
  filter: ReportFilter[]
  profile: string
  asOfDate: string
  format: number
  includeDisposals: boolean
  includeAssetDesc: boolean
  includePrimaryID: boolean
  includeSecondaryID: boolean
  includeCategoryDesc: boolean
  includeReference: boolean
  fileFormat: FileFormat
}

interface LocationHistoryReportParams {
  assetCompanyID: number
  filter: ReportFilter[]
  format: number
  includeDisposals: boolean
  includeAssetDesc: boolean
  includeSerialID: boolean
  includePrimaryID: boolean
  includeSecondaryID: boolean
  includeElapsedDays: boolean
  includeCategoryDesc: boolean
  fileFormat: FileFormat
}

interface LocationAccountingReportParams {
  assetCompanyID: number
  filter: ReportFilter[]
  format: number
  fromDate: string
  toDate: string
  includeDisposals: boolean
  includeAcqPostDesc: boolean
  includeDeprecPostDesc: boolean
  includeAssetDesc: boolean
  includeLocationDesc: boolean
  includeRef: boolean
  fileFormat: FileFormat
}

interface ServiceAnalysisReportParams {
  assetCompanyID: number
  filter: ReportFilter[]
  fromDate: string
  toDate: string
  format: number
  includeDisposals: boolean
  includeAssetDesc: boolean
  includeServiceDesc: boolean
  includeServiceBy: boolean
  includeReference: boolean
  includeNextServiceDate: boolean
  fileFormat: FileFormat
}

interface ServiceSchedulingReportParams {
  assetCompanyID: number
  filter: ReportFilter[]
  fromDate: string
  toDate: string
  format: number
  includeDisposals: boolean
  includeAssetDesc: boolean
  includeServiceDesc: boolean
  includeServiceBy: boolean
  includeReference: boolean
  includeServiceCode: boolean
  fileFormat: FileFormat
}

interface ServiceAccountingReportParams {
  assetCompanyID: number
  filter: ReportFilter[]
  fromDate: string
  toDate: string
  format: number
  includeDisposals: boolean
  includeServiceDesc: boolean
  includeServiceBy: boolean
  includeAssetDesc: boolean
  includeSerialID: boolean
  includeContractID: boolean
  fileFormat: FileFormat
}

interface TaskActivityAnalysisReportParams {
  assetCompanyID: number
  filter: ReportFilter[]
  fromDate: string
  toDate: string
  format: number
  includeDisposals: boolean
  includeTaskDesc: boolean
  includeAssetDesc: boolean
  includePerformedBy: boolean
  includeActivityDesc: boolean
  fileFormat: FileFormat
}

interface TaskActivityAccountingReportParams {
  assetCompanyID: number
  filter: ReportFilter[]
  fromDate: string
  toDate: string
  format: number
  includeDisposals: boolean
  includeAcqPostDesc: boolean
  includeDeprecPostDesc: boolean
  includeAssetDesc: boolean
  includeTaskDesc: boolean
  fileFormat: FileFormat
}

interface AssetByFoldersReportParams {
  // TODO
  assetCompanyID: number
  folders: Array<string>
}

export type {
  AssetRegisterSectionOptions as AssetRegisterSectionOption,
  ListReportParams,
  AssetRegisterReportParams,
  AdminInsuranceReportParams,
  AdminMaintenanceReportParams,
  AdminPropertiesReportParams,
  FinancialAdditionsReportParams,
  FinancialTransfersReportParams,
  FinancialDisposalsReportParams,
  FinancialCapitalizedCostsReportParams,
  FinancialGLPostSummaryReportParams,
  FinancialGLPostPreviewReportParams,
  DeprecSummaryReportParams,
  DeprecContinuityReportParams,
  DeprecHistoryReportParams,
  DeprecEditReportParams,
  DeprecComparisonReportParams,
  DeprecProjectionReportParams,
  DeprecProductionReportParams,
  PurchaseAnalysisReportParams,
  PurchaseAnalysisSpreadsheetReportParams,
  LocationCurrentReportParams,
  LocationHistoryReportParams,
  LocationAccountingReportParams,
  ServiceAnalysisReportParams,
  ServiceSchedulingReportParams,
  ServiceAccountingReportParams,
  TaskActivityAnalysisReportParams,
  TaskActivityAccountingReportParams,
  AssetByFoldersReportParams,
}
