import {
  Card,
  Checkbox,
  CustomDatePicker,
  Fieldset,
  Form,
  Grid,
  Spacer,
  toast,
} from '@components/common'
import { ReportsApi } from '@api'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
  DEPREC_PROFILES,
  ERROR_REQUEST,
  FINANCIAL_GLPOSTING_PREVIEW_REPORT_PATH,
} from '@constants'
import ReportFilters from '../ReportFilters'
import { glPostingReportsFilterFields } from '../ReportFilters/report-filter-fields'
import { reportResponseHandler } from '@utils/reports'
import { FinancialGLPostPreviewReportParams } from '@api/models'
import { DepProfileSelect, ActionButtons } from '../General'
import { useReport } from '@hooks'
import { useEffect } from 'react'
import { useMemorizeReport } from '../General/MemorizeReports/useMemorizeReport'
import MemorizeReports from '../General/MemorizeReports/MemorizeReport'
import { useFormMethods } from '../General/useFormMethods'
import { useLocation } from 'react-router-dom'

const defaultValues: FinancialGLPostPreviewReportParams = {
  assetCompanyID: 0,
  filter: [],
  profile: DEPREC_PROFILES.CORPORATE,
  totalsOnly: false,
  includeDisposals: false,
  includeAssetDesc: false,
  includeEventDesc: false,
  includeSystemDate: false,
  fileFormat: 'pdf',
}

async function onSubmitHandler(values: FinancialGLPostPreviewReportParams) {
  await ReportsApi.getInstance()
    .getFinancialGLPostPreviewReport(values)
    .then(data => reportResponseHandler(data, values.fileFormat))
    .catch(() => toast.error(ERROR_REQUEST))
}

const FinancialGLPPreviewForm = () => {
  const location = useLocation()
  const locationState: any = location.state

  const {
    methods,
    isRetrievingTemplate,
    isEditTemplate,
    selectedTemplateDetails,
  } = useFormMethods(locationState, defaultValues)

  const {
    formState: { isSubmitting, errors },
    control,
    setValue,
  } = methods

  const {
    depProfilesMap,
    isLoading,
    isSuccess,
    isError,
    isNoCompanies,
    assetCompanyID,
  } = useReport()

  const {
    handleMemorizeTemplateSubmit,
    isMemorizeTemplateModalOpen,
    closeMemorizeTemplateModal,
    triggerMemorizeTemplateModal,
    handleEditTemplateSubmit,
  } = useMemorizeReport({
    methods,
    currentReportPath: FINANCIAL_GLPOSTING_PREVIEW_REPORT_PATH,
  })

  useEffect(() => {
    if (isSuccess) {
      setValue('assetCompanyID', assetCompanyID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, assetCompanyID])

  function getCheckBoxProps(field: any) {
    return {
      checked: field.value,
      onChange: field.onChange,
      ref: field.ref,
      disabled: isSubmitting,
    }
  }

  function getDateProps(field: any) {
    return {
      onChange: field.onChange,
      ref: field.ref,
      disabled: isSubmitting,
      select: field.value,
      onBlur: field.onBlur,
      error: errors[field] !== undefined,
      errorMsg: errors[field]?.message,
    }
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  if (isNoCompanies) {
    return <div>No companies found</div>
  }

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmitHandler)}>
          <Card title={'Report Parameters'}>
            <Grid>
              <Grid.Item>
                <DepProfileSelect depProfilesMap={depProfilesMap} />
              </Grid.Item>

              <Grid.Item m={6}>
                <Fieldset legend={'Additional Columns To Include'}>
                  <Grid>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeAssetDesc"
                        render={({ field }) => (
                          <Checkbox
                            label="Include asset description"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeEventDesc"
                        render={({ field }) => (
                          <Checkbox
                            label="Include event description"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeSystemDate"
                        render={({ field }) => (
                          <Checkbox
                            label="Include system date"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                  </Grid>
                </Fieldset>
              </Grid.Item>

              <Grid.Item m={6}>
                <Fieldset legend={'Options'}>
                  <Grid>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="totalsOnly"
                        render={({ field }) => (
                          <Checkbox label="Totals only" {...getCheckBoxProps(field)} />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeDisposals"
                        render={({ field }) => (
                          <Checkbox
                            label="Include disposals"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                  </Grid>
                </Fieldset>
              </Grid.Item>
              <Grid.Item>
                <ReportFilters
                  filtersTemplateData={
                    isRetrievingTemplate
                      ? locationState.templateDetails.templateData.filter
                      : []
                  }
                  filterFields={glPostingReportsFilterFields}
                />
              </Grid.Item>
            </Grid>
          </Card>
          <Spacer y="default" />
          <ActionButtons
            isSubmitting={isSubmitting}
            triggerMemorizeTemplateModal={triggerMemorizeTemplateModal}
            isEditTemplate={isEditTemplate}
          />
        </Form>
      </FormProvider>

      <MemorizeReports
        isMemorizeTemplateModalOpen={isMemorizeTemplateModalOpen}
        closeMemorizeTemplateModal={closeMemorizeTemplateModal}
        handleMemorizeTemplateSubmit={handleMemorizeTemplateSubmit}
        handleEditTemplateSubmit={handleEditTemplateSubmit}
        isEditTemplate={isEditTemplate}
        selectedTemplateDetails={selectedTemplateDetails}
      />
    </>
  )
}

export default FinancialGLPPreviewForm
