import { apiSlice } from '@api'
import { StandardFetchRequestPayload } from '@api/models'
import { ListVendorsResponse, FetchVendorsResponse, Vendor } from './models'

export const vendorEndpoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    listVendors: build.query<ListVendorsResponse, void>({
      query: () => `Vendor/ListVendors`,
    }),
    fetchVendors: build.query<FetchVendorsResponse, StandardFetchRequestPayload>({
      query: ({ pageNumber, itemsPerPage, filter }) =>
        `Vendor/FetchVendors?pageNumber=${pageNumber}&itemsPerPage${itemsPerPage}&filter=${encodeURIComponent(
          JSON.stringify(filter)
        )}`,
      providesTags: ['vendor'],
    }),
    createVendor: build.mutation<void, Vendor>({
      query: ({ ...vendor }) => ({
        url: 'Vendor/Create',
        method: 'POST',
        body: { ...vendor },
      }),
      invalidatesTags: ['vendor', 'assetCharge'],
    }),
    editVendor: build.mutation<void, Vendor>({
      query: ({ ...vendor }) => ({
        url: 'Vendor/Edit',
        method: 'PUT',
        body: { ...vendor },
      }),
      invalidatesTags: ['vendor', 'assetCharge'],
    }),
    deleteVendor: build.mutation<void, Vendor>({
      query: ({ ...vendor }) => ({
        url: 'Vendor/Delete',
        method: 'POST',
        body: { ...vendor },
      }),
      invalidatesTags: ['vendor', 'assetCharge'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useListVendorsQuery,
  useFetchVendorsQuery,
  useCreateVendorMutation,
  useEditVendorMutation,
  useDeleteVendorMutation,
} = vendorEndpoints
