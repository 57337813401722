import { apiSlice } from '@api'
import { StandardFetchRequestPayload } from '@api/models'
import { FetchTasksResponse, Task } from './models'

export const tasksEndpoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    fetchTasks: build.query<FetchTasksResponse, StandardFetchRequestPayload>({
      query: ({ pageNumber, itemsPerPage, filter }) =>
        `Other/FetchOthers?pageNumber=${pageNumber}&itemsPerPage${itemsPerPage}&filter=${encodeURIComponent(
          JSON.stringify(filter)
        )}`,
      providesTags: ['task'],
    }),
    createTask: build.mutation<void, Task>({
      query: ({ ...task }) => ({
        url: 'Other/Create',
        method: 'POST',
        body: { ...task },
      }),
      invalidatesTags: ['task'],
    }),
    editTask: build.mutation<void, Task>({
      query: ({ ...task }) => ({
        url: 'Other/Edit',
        method: 'PUT',
        body: { ...task },
      }),
      invalidatesTags: ['task'],
    }),
    deleteTask: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `Other/Delete/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['task'],
    }),
    getTask: build.query<Task, string>({
      query: id => `Other/Get/${id}`,
    }),
  }),
  overrideExisting: false,
})

export const {
  useFetchTasksQuery,
  useDeleteTaskMutation,
  useEditTaskMutation,
  useCreateTaskMutation,
  useGetTaskQuery,
} = tasksEndpoints
